import { MIME } from '@/assets/js/mime.js'
import { exportForm } from '@/assets/js/http.js'
export const fileHandle = {
  handlePreview (file, download) {
    exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
      (response) => {
        var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
        var mime = 'application/octet-stream'
        if (MIME[fileExtension]) {
          mime = MIME[fileExtension]
        }
        const aBlob = new Blob([response.data], { type: mime })
        if (download === true) {
          mime = 'download'
        }
        this.downloadByBlob(aBlob, file.name, mime)
      })
  },
  downloadByBlob (data, fileName, mime) {
    try {
      const url = window.URL.createObjectURL(data)
      const eleLink = document.createElement('a')
      eleLink.href = url
      eleLink.target = '_blank'
      if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
        eleLink.download = fileName
      }
      eleLink.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('download function error!', error)
    }
  }
}
