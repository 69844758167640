<template>
<!-- 侧边抽屉 -->
  <div class="rightinfo">
      <div class="message" v-html="textData"></div>
  </div>
</template>

<script>
export default {
  name: 'MessageNotification',
  props: ['textData'],
  data () {
    return {
    }
  }

}
</script>

<style scoped lang="scss">
.rightinfo{
    width: 100%;
    height: 100%;
    border-top: 1px solid #E4E7ED;
    padding: 10px;
}
</style>
