<template>
<!-- tab子组件 -->
<div class="hiddensol">
  <div class="tabtablelist">
      <div class="border" :key="item.id" v-for="item in listData">
          <el-button  type="text" @click="openDrawer(item)">• {{item.title}} </el-button>
          <span class="datetime">{{item.time.split('.')[0]}}</span>
      </div>
      <el-drawer :title="title" v-model="drawer" :before-close="handleClose" destroy-on-close>
        <message-notification :textData='textData'></message-notification>
      </el-drawer>
  </div>
</div>
</template>

<script>
import MessageNotification from '../module/MessageNotification.vue'
import { request } from '@/assets/js/http.js'
import { fileHandle } from '@/assets/js/FileAction.js'
export default {
  name: 'SystemNotice',
  components: { MessageNotification },
  data () {
    return {
      listData: [],
      drawer: false,
      title: '',
      textData: ''
    }
  },
  created () {
    request('/api/system/message/getMessage', 'post', true, 'application/json').then((res) => {
      if (res) {
        this.listData = [...res, ...this.listData]
      }
    })
    request('/api/system/message/getMessage', 'post', 'url', 'application/json').then((res) => {
      if (res) {
        this.listData = [...res, ...this.listData]
      }
    })
  },
  methods: {
    handleClose () { // 右侧弹出框
      this.drawer = false
    },
    openDrawer (data) {
      if (data.flag === 'url') {
        fileHandle.handlePreview(JSON.parse(data.text))
      } else {
        this.title = data.title
        this.textData = data.text
        this.drawer = true
      }
    }
  }
}
</script>
<style scoped lang="scss">
.border :hover{
color: #409EFF;
}
.border{
  justify-content: space-between;
  display: flex;
  height: 39px;
  margin-top: 0px;
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #EBEEF5;
  .datetime{
    width: 140px;
    padding: 10px 0px;
    white-space: nowrap;
}
}
.tabtablelist{
    height: calc(100vh - 643px);
    overflow: auto;
    min-height: 340px;
    margin-right: -17px;
    padding-right: 17px
}
.hiddensol{
  width: 100%;
  overflow: hidden;
}
</style>
